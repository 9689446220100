<template>
  <div class="bank">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">乡村振兴</div>
    <div class="card">
      <img src="../../assets/img/xczx.jpg" alt="">
      <p>乡村振兴板块以全面服务农业农村发展为宗旨，以归集农户和新型农业经营主体的信用信息为基础，搭建融资对接、农业产业链金融、供求市场、招工用工、家政服务等应用场景，对接金融机构的专项线上产品，实现农村政府管理、金融服务、电子商务、支付、农资服务、农业产融联合一体化，为农村经济主体提供高效、实用的综合服务。</p>
    </div>
  </div>
</template>
<script>
export default {
   setup() {
    return { 
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.bank {
  padding-top: 12.5vw;
  .card {
    img {
      width: 100%;
      height: auto;
      border-radius: 1.5vw;
    }
    p {
      margin-top: 3vw;
      font-size: 4vw;
    letter-spacing: 1px;
    line-height: 6vw;
    }
  }
}
</style>
